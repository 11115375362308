<template>
    <div class="content-wrapper">
        <PageHeader screenName="Editar" :linkItems="linkItems"/>

        <ConfirmModal
            itemIdentifier="aviso"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="redirecionarLista"
            @canceled="closeModalConfirm"
        />

        <ConfirmModalInativar
            itemIdentifier="aviso"
            :modalShow="modalInativar.showModal"
            :typeModal="modalInativar.typeModal"
            @confirmed="changeStateAviso"
            @canceled="closeModalInativar"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirecionarLista"
        />

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2">
            <section class="form">
                <b-alert class="p-1" variant="danger" :show="form.errorApi.error ? true : false">
                    {{ form.errorApi.error }}
                </b-alert>
                <validation-observer ref="avisoRules">
                    <b-form>
                        <b-row>
                            <b-col lg="8" md="8" sm="12">
                                <b-form-group label="Título do aviso*">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="titulo"
                                        rules="required"
                                    >
                                    <b-form-input
                                            :state="errors.length > 0 || form.errorApi.titulo || form.errorApi.error ? false:null"
                                            id="titulo-input"
                                            v-model="form.titulo"
                                            placeholder="Adicione um título para este aviso"
                                    />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.titulo" class="text-danger">
                                            {{ form.errorApi.titulo }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" md="12" sm="12">
                                <b-form-group label="Descrição do aviso*">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="conteudo"
                                        rules="required"
                                    >
                                        <b-form-textarea
                                            :state="errors.length > 0 || form.errorApi.conteudo || form.errorApi.error ? false:null"
                                            id="conteudo-input"
                                            v-on:keyup="liveCountDown"
                                            rows="5"
                                            v-model="form.conteudo"
                                            placeholder="Adicione o conteúdo que será mostrado para este aviso"
                                            no-resize
                                        />
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.conteudo" class="text-danger">
                                            {{ form.errorApi.conteudo }}
                                        </small>
                                        <small v-if="errorTextarea" class="text-danger">
                                            {{ errorTextarea }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                           <b-col lg="6" md="6" sm="12" v-if="agendamento">
                               <b-form-group label="Início">
                                <validation-provider
                                    name="vigencia_inicial"
                                >
                                    <b-input-group>
                                        <vue-cleave
                                            id="vigencia_final-input"
                                            v-model="input.vigencia_inicial"
                                            :raw="false"
                                            :options="data"
                                            class="form-control"
                                            placeholder="00/00/0000"
                                            name="vigencia_inicial"
                                            autocomplete="off"
                                            @input="setVigencia_inicialInput()"
                                        />
                                        <b-input-group-append>
                                            <b-form-datepicker
                                            locale="pt-BR"
                                            button-only
                                            right
                                            :hide-header="true"
                                            v-model="form.vigencia_inicial"
                                            @input="setVigencia_inicialPicker(false)"
                                            class="form-control d-flex align-items-center"
                                            label-help="Selecione a data de início"
                                            :min="today"
                                            button-variant="custom-blue"
                                            />
                                            </b-input-group-append>
                                    </b-input-group>
                                        <small v-if="form.errorApi.vigencia_inicial" class="text-danger">
                                            {{ form.errorApi.vigencia_inicial }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                </validation-provider>
                               </b-form-group>
                               <b-form-group label="Término">
                                   <validation-provider
                                    name="vigencia_final"
                                    >
                                       <b-input-group>
                                        <vue-cleave
                                            id="vigencia_final-input"
                                            v-model="input.vigencia_final"
                                            :raw="false"
                                            :options="data"
                                            class="form-control"
                                            placeholder="00/00/0000"
                                            name="vigencia_final"
                                            autocomplete="off"
                                            @input="setVigencia_finalInput"
                                        />
                                        <b-input-group-append>
                                        <b-form-datepicker
                                        locale="pt-BR"
                                        button-only
                                        right
                                        :hide-header="true"
                                        v-model="form.vigencia_final"
                                        :min="form.vigencia_inicial ? form.vigencia_inicial : today"
                                        class="form-control d-flex align-items-center"
                                        label-help="Selecione a data de término"
                                        placeholder="Selecione a data de término"
                                        button-variant="custom-blue"
                                        clearable
                                        @input="setVigencia_finalPicker"
                                        />
                                        </b-input-group-append>
                                    </b-input-group>
                                        <small v-if="form.errorApi.vigencia_final" class="text-danger">
                                            {{ form.errorApi.vigencia_final }}
                                        </small>
                                        <small v-if="form.errorApi.error" class="text-danger">
                                            {{ form.errorApi.error }}
                                        </small>
                                   </validation-provider>
                               </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group label="Perfis a serem encaminhados*">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Perfis"
                                        rules="required"
                                    >
                                        <input type="hidden" v-model="perfisSelecionados">
                                        <SelectPerfis
                                        @GetPerfis="setPerfil"
                                        :values="perfisSelecionados"
                                        :state="errors.length > 0 || form.errorApi.perfisSelecionados ? false:null"
                                        />
                                        <small class="text-muted">
                                            Mais de uma opção pode ser selecionada.
                                        </small>
                                        <br>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.perfisSelecionados" class="text-danger">
                                            {{ form.errorApi.perfisSelecionados }}
                                        </small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="ajudaRelacionada"
                                >
                                    <b-form-group
                                    label="Ajuda relacionada"
                                    :state="errors.length > 0 || form.errorApi.ajudaRelacionada ? false:null">
                                        <v-select
                                            id="ajudaRelacionada"
                                            v-model="form.ajudaRelacionada"
                                            :options="ajudaRelacionadaSelect"
                                            label="topico"
                                            placeholder="Selecione a ajuda relacionada a esse aviso"
                                            :clearable="true"
                                            :reduce="(option => option.id_ajuda)"
                                        >
                                            <span slot="no-options">Nenhuma opção selecionável.</span>
                                        </v-select>
                                        <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                        </small>
                                        <small v-if="form.errorApi.ajudaRelacionada" class="text-danger">
                                            {{ form.errorApi.ajudaRelacionada }}
                                        </small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>
            <section class="buttons mt-3">
                <b-button id="save-aviso"
                    class="mr-2 mb-2-sm"
                    variant="custom-blue"
                    @click.prevent="validationForm(true)"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Salvar aviso</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-register-aviso"
                    :disabled="saving"
                    class="mr-2 mb-2-sm"
                    :variant="botaoInativar.variant"
                    @click.prevent="openModalInativar(botaoInativar.type)"
                >
                    <feather-icon icon="AlertCircleIcon" class="mr-50"/>
                    <span class="align-middle">{{ botaoInativar.text }}</span>
                </b-button>
                <b-button id="cancel-register-aviso"
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openModalConfirm"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Cancelar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BAlert, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea, BFormDatepicker, BCardText, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModalInativar from '@/views/components/custom/modals/ConfirmAtivarDeleteAvisoModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import SelectPerfis from '@/views/custom-pages/aviso/Select-perfis.vue'
import VueCleave from 'vue-cleave-component'
import moment from 'moment'
import handleErrorsService from './Services/HandleErrors';
import { TIPONOTIFICACAO } from '@/enum/tipo-notificacao'
import { getIdAviso, getPerfisSelecionadosAviso } from '@core/utils/store/getStore';
import { setIdAviso, setPerfisSelecionadosAviso } from "@core/utils/store/setStore";

export default {
    title: 'Editar aviso',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, vSelect, ValidationProvider, ValidationObserver, BAlert,
        PageHeader, ConfirmModalInativar, SucessoModal, ErrorModal, VueCleave,
        BFormDatepicker, BCardText, SelectPerfis, ConfirmModal, BInputGroup, BInputGroupAppend
    },
    data() {
        return {
            linkItems: [
                {
                    name: 'Avisos',
                    routeName: 'aviso-list'
                },
                {
                    name: 'Editar',
                    active: true
                }
            ],
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalInativar: {
                showModal: false,
                typeModal: 'warning'
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'edicao'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            data: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
            },
            required,
            savedAlert: false,
            saving: false,
            limitmaxCount: 250,
            totalRemainCount: 250,
            errorTextarea: '',
            today: moment().format('YYYY-MM-DD'),
            minDate: moment().format('YYYY-MM-DD'),
            agendamento: false,
            form: {
                titulo:  '',
                conteudo: '',
                vigencia_inicial: '',
                vigencia_final: '',
                ativo: '',
                ajudaRelacionada: null,
                errorApi: {
                    titulo:  '',
                    conteudo: '',
                    vigencia_inicial: '',
                    vigencia_final: '',
                    ativo: '',
                    ajudaRelacionada: '',
                    perfisSelecionados: '',
                    error: ''
                }
            },
            input: {
                vigencia_inicial: '',
                vigencia_final: '',
            },
            botaoInativar: {
                variant: '',
                text: '',
                type: ''
            },
            perfisSelecionados: [],
            ajudaRelacionadaSelect: [],
            idAvisoEditar : null,
        }
    },

    mounted(){
        if((getIdAviso() === null || typeof getIdAviso() == 'undefined') && typeof this.$route.params.idNotificacao == "undefined") {
            return this.openModalError();
        }else if(typeof this.$route.params.idNotificacao != "undefined" && this.$route.params.idNotificacao !== getIdAviso()) {
            setIdAviso(this.$route.params.idNotificacao);
        }

        if(typeof this.$route.params.perfisSelecionados != "undefined" && this.$route.params.perfisSelecionados !== getPerfisSelecionadosAviso()) {
            setPerfisSelecionadosAviso(this.$route.params.perfisSelecionados);
        }

        this.$http.get(this.$api.notificacaoParameter(getIdAviso())).then(({ data }) => {
            this.preenchimentoFormulario(data);
        });

        this.$http.get(this.$api.ajuda()).then(({ data }) => {
            this.ajudaRelacionadaSelect = data.data;
        });
    },

    methods: {
        validationForm(redirectToList) {
            this.$refs.avisoRules.validate().then(success => {
                if (success) {
                    if(redirectToList){
                        this.saving = true
                    }
                    this.editAviso(redirectToList);
                }
            })
        },
        editAviso(redirectToList) {
            const parameters = {
                'titulo': this.form.titulo,
                'conteudo': this.form.conteudo,
                'id_tipo_notificacao': TIPONOTIFICACAO.AVISO,
                'vigencia_inicial': this.form.vigencia_inicial ? this.form.vigencia_inicial : moment().format('YYYY-MM-DD') ,
                'vigencia_final': this.form.vigencia_final ? this.form.vigencia_final : this.validateVigencia_final(this.form.vigencia_final, this.form.vigencia_inicial),
                'id_ajuda': this.form.ajudaRelacionada,
                'ids_perfis': this.$helpers.retornaAtributoArray(this.perfisSelecionados, 'id_perfil')
            }

            this.$http.put(this.$api.notificacaoParameter(this.idAvisoEditar), parameters).then(() => {
                if(redirectToList) {
                    this.openModalSucesso();
                }

                this.saving = false;
                this.cleanForm();

            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosAviso(data, this.form.errorApi);

                this.saving = false;
            })
        },
        cleanForm() {
            this.form.titulo = '';
            this.form.conteudo = '';
            this.form.vigencia_inicial = '';
            this.form.vigencia_final = '';
            this.form.ajudaRelacionada = '';
            this.perfisSelecionados = [];

            this.form.errorApi.titulo = '';
            this.form.errorApi.conteudo = '';
            this.form.errorApi.vigencia_inicial = '';
            this.form.errorApi.vigencia_final = '';
            this.form.errorApi.ajudaRelacionada = '';
            this.form.errorApi.error = '';

            this.$refs.avisoRules.reset();
        },

        clearDataVigencia_final(){
            this.form.vigencia_final = null;
            this.input.vigencia_final = null;
        },
        perfilSelecionadoAjuste(perfisBruto) {
            let idsPerfisSelecionados = this.$helpers.returnPgArrayToArray(perfisBruto, ',');
            let perfisSelecionados = [];
            idsPerfisSelecionados.forEach((perfil) => {
                this.$http.get(this.$api.getPerfil(perfil)).then(({data}) => {
                    perfisSelecionados.push(data);
                })
            })
            return perfisSelecionados;
        },
        preenchimentoFormulario(data){
            this.idAvisoEditar  = getIdAviso();
            this.form.titulo = data.titulo;
            this.form.conteudo = data.conteudo;
            this.form.inicio = data.inicio;
            this.form.termino = data.termino;
            this.form.vigencia_inicial = data.vigencia_inicial;
            this.form.vigencia_final = data.vigencia_final;

            this.form.ativo = data.ativo;
            this.form.ajudaRelacionada = data.id_ajuda;
            this.perfisSelecionados = this.perfilSelecionadoAjuste(getPerfisSelecionadosAviso());

            if(!this.form.ativo){
                this.botaoInativar.variant = 'outline-success';
                this.botaoInativar.text = 'Ativar esse aviso';
                this.botaoInativar.type = 'success';
            }else{
                this.botaoInativar.variant = 'outline-warning';
                this.botaoInativar.text = 'Inativar esse aviso';
                this.botaoInativar.type = 'warning';
            }
            this.setVigencia_inicialPicker(true);
            this.setVigencia_finalPicker();
        },
        changeStateAviso() {
            this.$http.patch(this.$api.notificacaoParameter(this.idAvisoEditar)).then(() => {
                this.redirecionarLista();

            }).catch(() => {
                this.openModalError();
            })
        },
        redirecionarLista() {
            this.$router.push({ name: 'aviso-list' });
        },
        openModalError() {
            this.modalError.showModal = true;
        },
        openModalConfirm() {
            this.modal.showModal = true;
        },

        closeModalConfirm() {
            this.modal.showModal = false;
        },

        openModalInativar(type) {
            this.modalInativar.typeModal = type;
            this.modalInativar.showModal = true;
        },

        closeModalInativar() {
            this.modalInativar.showModal = false;
        },

        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },
        setPerfil(perfilId) {
            this.perfisSelecionados = perfilId;
        },
        setVigencia_inicialPicker(first = false) {
            if (!first) {
                this.clearDataVigencia_final();
            }
            this.input.vigencia_inicial = moment(this.form.vigencia_inicial).format('DD/MM/YYYY');
        },
        setVigencia_inicialInput() {
            this.form.vigencia_inicial = moment(this.input.vigencia_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        setVigencia_finalPicker() {
            this.input.vigencia_final = moment(this.form.vigencia_final).format('DD/MM/YYYY');
        },
        setVigencia_finalInput() {
            this.form.vigencia_final = moment(this.input.vigencia_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        validateVigencia_final(vigencia_final, vigencia_inicial){
            if(vigencia_inicial && !vigencia_final) {
                return moment(vigencia_inicial).add(7, 'd').format('YYYY-MM-DD');
            }else{
                return moment().add(7, 'd').format('YYYY-MM-DD');
            }
        },
        liveCountDown() {
            this.totalRemainCount = this.limitmaxCount - this.form.conteudo.length;
            if(this.totalRemainCount < 0){
                this.errorTextarea = 'O número de caracteres foi excedido.'
            }else{
                this.errorTextarea = '';
            }
        }
    }

}
</script>
