<template>
      <v-select
      id="Select-Perfis"
      multiselect
      multiple
      v-model="PerfilSelecionado"
      @input="SelecionaPerfil"
      variant="custom"
      item-text="descricao"
      item-value="id_perfil"
      label="descricao"
      placeholder="Selecione o perfil das pessoas que receberão o aviso"
      :values="Perfis"
      :disabled="Disabled"
      :options="Perfis"
    >
      <span slot="no-options">Nenhuma opção selecionável.</span>
    </v-select>
</template> 

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

import { BCol, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: [],
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      PerfilSelecionado: [],
      Perfis: [],
      Disabled: false
    };
  },
  methods: {
    SelecionaPerfil() {
      this.$emit("GetPerfis", this.PerfilSelecionado);
    },
    buscaPerfis() {
      let params = {
        todos: true
      };

      this.$http.get(this.$api.getPerfis(), {params}).then((res) => {
        this.Perfis = res.data;
      });
    }
  },

  watch: {
    values(value) {
      this.PerfilSelecionado = value;
    },
    disabledProp(value) {
      this.Disabled = value;
    },
  },

  created() {
    this.buscaPerfis();
  },

}
</script>