export default {
    handleErrosAviso(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.titulo = error.titulo ? error.titulo[0] : '';
        errorForm.conteudo = error.conteudo ? error.conteudo[0] : '';
        errorForm.inicio = error.inicio ? error.inicio[0] : '';
        errorForm.termino = error.termino ? error.termino[0] : '';
        errorForm.ajudaRelacionada = error.id_ajuda ? error.id_ajuda[0] : '';
        errorForm.perfisSelecionados = error.ids_perfis ? error.ids_perfis[0] : '';
        errorForm.error = '';

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    }
}